.section-title
	display: block
	margin-bottom: 5px
	padding: 5px 0
	font-family: $baseFont
	font-size: 10px
	font-weight: 300
	text-transform: uppercase
	letter-spacing: 2px
	color: #b2b2b2
	&::first-letter
		font-size: 1.25em

	.site-col--small &
		margin-bottom: 15px
		padding: 10px 0
		border-bottom: 1px #e2e2e2 solid
		