.pagination
  display: block
  text-align: center
  ul
    display: inline-block
    white-space: nowrap
    li
      display: inline-block
      vertical-align: middle
      margin: 0 1px
      padding: 0
      &::before
        display: none
      &.disabled
        a, span
          color: #aaa
          cursor: default
      a, span
        display: block
        padding: 5px
        min-width: 25px
        font-size: 12px
        text-transform: uppercase
        text-decoration: none
        text-align: center
        line-height: 1
        letter-spacing: 1px
        color: $fontSecondaryColor
        border-radius: 50%
      &:not(:first-child):not(:last-child)
        a, span
          border: 1px #e5e5e5 solid
        &.current
          a, span
            background: #e5e5e5
        &:hover
          a, span
            color: #fff
            background: $baseColor
            border-color: $baseColor
      &:first-child, &:last-child
        a, span
          font-size: 10px
          &:hover
            color: $secondaryColor
      &:first-child
        margin-right: 15px
      &:last-child
        margin-left: 15px


+media-query-max($mobile)
  .pagination
    ul li
      &:first-child, &:last-child
        a
          font-size: 0
        i
          font-size: 16px