.post-tags
	display: block
	position: relative
	ul
		margin: -5px
		li
			display: inline-block
			vertical-align: top
			margin: 5px 3px
			padding: 0
			overflow: hidden
			&::before
				display: none
			a
				display: block
				padding: 8px 13px
				font-size: 12px
				font-weight: 300
				text-decoration: none
				color: $fontBaseColor
				line-height: 1
				background: #fff
				border: 1px #ddd solid
				border-radius: 50px
				+transition(all 200ms linear)
				&:hover
					color: $baseColor
					border-color: $baseColor