.small-posts
	position: relative
	display: block
	&__post
		margin-bottom: 30px
	.post-date
		margin-bottom: 10px
		p
			font-size: 11px
	.post-title
		position: relative
		h5
			font-size: 16px
		&::before
			content: "\f054"
			font-family: FontAwesome
			position: absolute
			top: 0
			left: -15px
			font-size: 12px
			color: #ddd
			line-height: 1.6