@import "vendor/normalize"
@import "vendor/bootstrap-grid"

@import "bourbon"
@import "vendor/animate/animate"
@import "vendor/font-awesome/font-awesome"


@font-face 
  font-family: 'QuestaGrande'
  src: url('../fonts/QuestaGrande/Questa_Grande_Regular-webfont.woff2') format('woff2')
  font-weight: 400
  font-style: normal


$baseColor: #0e3178
$secondaryColor: #e40020
$thirdColor: #f2f0ef

$baseFont: "proxima-nova", Arial, sans-serif
$secondaryFont: "QuestaGrande", Verdana, Verdana, sans-serif

$fontBaseColor: #777
$fontSecondaryColor: #0e3178
$linkColor: #e40020

$background: #fff
$siteWidth: 1200px
$netbook: 1024px
$tablet: 860px
$xtablet: 760px
$mobile: 640px
$xmobile: 460px




/*** MIXINS ***/
@mixin clearfix
  &:after
    clear: both
    content: ""
    display: block
    visibility: hidden

@mixin wrapper($width)
  display: block
  position: relative
  width: 100%
  max-width: $width
  margin: 0 auto
  padding: 0 20px
  box-sizing: border-box
  +clearfix

@mixin justified
  &:after
    content: ""
    width: 100%
    display: inline-block
    *zoom: 1

@mixin fluid-image
  position: absolute
  min-width: 100%
  min-height: 100%
  left: -50%
  right: -50%
  top: -1%
  bottom: -1%
  margin: auto
  +backface-visibility(hidden)
  +transform(translateZ(0) scale(1))
  +transition(all 150ms ease-out)

@mixin blur 
  filter: blur(5px)
  -webkit-filter: blur(5px)
  -moz-filter: blur(5px)
  -o-filter: blur(5px)
  -ms-filter: blur(5px)
  filter: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQoJPGZpbHRlciBpZD0iYmx1ciI+DQoJCTxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjMiIC8+DQoJPC9maWx0ZXI+DQo8L3N2Zz4=#blur)

@mixin media-query-max($media-query)
  @media only screen and(max-width:$media-query)
    @content
    
@mixin media-query-min($media-query)
  @media only screen and(min-width:$media-query)
    @content



@mixin numbered-list
  &::before
    counter-increment: list
    content: counter(list)
    color: inherit
    font-weight: 400
    display: inline-block
    position: absolute
    left: 0