.site-columns
	display: table
	width: 100%
	margin: 60px 0 30px 0
	.site-col
		display: table-cell
		vertical-align: top
		&--small
			width: 20%
			> div
				margin-bottom: 20px
		&--large
			padding: 0 5%


+media-query-max($netbook)
	.site-columns
		display: block
		.site-col
			display: block
			float: left
			&--small
				width: 35%
			&--large
				width: 65%
				padding: 0 30px 0 0
			&:first-child
				float: right
				margin-bottom: 20px

+media-query-max($xtablet)
	.site-columns
		.site-col
			width: 100%
			&--large
				padding: 0