.site-square
	display: block
	margin: 30px auto
	width: 100%
	max-width: 770px
	background: #fff
	border: 10px #fff solid
	border-radius: 5px
	box-shadow: 0px 0px 60px -5px #aaa
	.text
		padding: 35px 50px 35px 35px