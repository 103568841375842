.sidebar-menu
	display: block
	li
		display: block
		margin: 10px 0
		padding: 0
		&::before
			display: none
		&.active a
			font-weight: 600
			color: $linkColor
		a
			display: block
			font-weight: 300
			text-decoration: none
			color: $fontBaseColor
			line-height: 1.5
			&:hover
				color: $fontSecondaryColor