.site-header
  position: relative
  display: block
  background: #fff
  border-bottom: 1px #ddd solid
  z-index: 999
  +flex-grow(0)
  +order(111)
  &__cols
    display: table
    width: 100%
  &__col
    display: table-cell
    vertical-align: middle

  .site-logo
    img, svg
      display: block
      width: 100%
      max-width: 260px
  .menu-hamburger
    display: none
  nav
    float: right
    +clearfix
    ul
      display: table
      float: left
      li
        display: table-cell
        vertical-align: middle
        padding: 5px 10px
        &::before
          display: none
        &.current
          a, span
            font-weight: 700
            color: $linkColor
        a, span
          display: inline-block
          vertical-align: middle
          padding: 5px 10px
          font-size: 12px
          font-weight: 400
          text-transform: uppercase
          text-decoration: none
          letter-spacing: 1px
          color: inherit
          &:hover
            color: #ddd

    ul.menu--awarded
      margin-left: 20px
      padding: 0 10px
      color: #fff
      background: $baseColor
      border-radius: 30px
      li
        padding: 5px 0
        &:not(:last-child)
          border-right: 1px rgba(255, 255, 255, 0.2) solid
        a
          font-weight: 200
          letter-spacing: 0px
          line-height: 1

.m-site-navigation
  display: none
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  margin: auto
  padding: 25px 20px
  background: rgba(0, 0, 0, 0.95)
  z-index: 99999
  a.menu-trigger
    float: none
    color: #fff
    span.hamburger__lines:before,
    span.hamburger__lines:after
      background-color: #fff !important
  nav
    position: absolute
    display: block
    top: 50%
    left: 0
    right: 0
    margin: auto
    text-align: center
    +transform(translateY(-50%))
    ul
      display: block
      vertical-align: middle
      li
        position: relative
        display: block
        margin: 0
        padding: 0
        color: #fff
        &::before
          display: none
        a
          display: block
          padding: 10px 0
          font-size: 24px
          font-weight: 100
          text-transform: uppercase
          color: #fff
          &:hover
            color: #aaa
        &.current
          a
            font-weight: 700


+media-query-max($netbook)
  .site-header
    nav
      ul
        li
          padding: 5px  0

+media-query-max($tablet)
  .site-header
    .menu-hamburger
      display: block
    nav
      display: none
