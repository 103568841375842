.site-category
	position: relative
	display: block
	text-align: center
	p
		font-size: 14px
		font-weight: 300
		text-transform: uppercase
		letter-spacing: 2px
		color: #b2b2b2
		&::first-letter
			font-size: 1.25em
		a, span
			text-decoration: none
			color: #666666
		a:hover
			color: #000

.site-title + .site-category
	margin-top: -30px