.download
	display: block
	width: 100%
	padding: 15px 0 5px 0
	h1, h2, h3, h4, h5
		margin-bottom: 15px
	&__file
		display: table
		min-height: 40px
	.layout-col--3 &, .layout-col--4 &
		display: block
		.file
			&__format
				display: none
			&__description
				padding: 10px
				border: 1px $baseColor solid
	.file
		&__format
			position: relative
			display: table-cell
			vertical-align: middle
			width: 37px
			font-size: 10px !important
			font-weight: 500
			text-transform: uppercase
			background: url(../images/icons/file.svg) top left no-repeat
			background-size: 24px 32px
			background-position: top left
			background-repeat: no-repeat
			span
				position: absolute
				top: 26px
				right: 0px
				font-weight: 600
				line-height: 1
		&__description
			display: table-cell
			vertical-align: middle
			padding-left: 10px
		&__title
			margin-bottom: 3px
			font-size: 16px
			font-weight: 300
			line-height: 1.1
			span
				position: relative
				padding-left: 5px
				font-size: 0.8em
				font-weight: 400
				color: #aaa
				white-space: nowrap
				padding-left: 20px
				&::before
					content: ""
					position: absolute
					top: 0
					bottom: 0
					left: 0
					margin: auto
					width: 15px
					height: 1px
					background: #aaa

		