.intro
	+display(flex)
	+flex-grow(1)
	text-align: center
	color: #fff
	background: #eee url(../images/slider/0.jpg) center center no-repeat
	background-size: cover
	&__content
		+display(flex)
		+flex-grow(1)
		+flex-direction(column)
		max-width: $siteWidth
		margin: 0 auto
		padding: 0 20px
	.intro-row
		+display(flex)
		+flex-grow(1)
		+flex-direction(row)
		position: relative
		&:nth-child(1)
			+align-items(center)
		&:nth-child(2)
			+align-items(center)
		&:nth-child(3)
			+align-items(flex-end)
			padding-bottom: 50px
	.intro-slogan, .buttons
		width: 100%
		h1, h2, h3, h4, h5
			color: inherit
		h4
			opacity: 0.6
		a
			color: #fff


+media-query-max($netbook)
	.intro
		.intro-row
			padding: 30px 0