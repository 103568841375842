.posts-list
	position: relative
	display: block
	&__post
		position: relative
		display: block
		width: 100%
		margin-bottom: 20px
		padding-bottom: 50px
		&:not(:last-child)
			&::after
				content: "\2022  \2022  \2022"
				position: absolute
				bottom: 0
				left: 0
				right: 0
				margin: auto
				font-size: 26px
				text-align: center
				color: #e5e5e5


