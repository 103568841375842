.large-image
  &__wrapper
    position: fixed
    max-width: 80%
    max-height: 80%
    z-index: 9999
    -webkit-transition: all 300ms ease-in-out, -webkit-transform 0ms
    -moz-transition: all 300ms ease-in-out, -moz-transform 0ms
    transition: all 300ms ease-in-out, transform 0ms
    &::after
      content: url(../scripts/vendor/fluid-image/images/spinner.gif)
      position: absolute
      top: 0
      bottom: 0
      left: 0
      right: 0
      margin: auto
      width: 16px
      height: 16px
      opacity: 1
      z-index: 9
      -webkit-transition: opacity 100ms linear
      -moz-transition: opacity 100ms linear
      transition: opacity 100ms linear
    &.loaded
      &::after
        opacity: 0
    img
      position: absolute
      top: 0
      left: 100%
      display: block
      opacity: 0
      z-index: 99
      &.included
        left: 0
        right: 0
        margin: auto
        max-width: 100%
        max-height: 100%
        opacity: 1

  &__overlay
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    color: #fff
    background: rgba(0, 0, 0, 0)
    z-index: 99999
    -webkit-transition: background 150ms linear
    -moz-transition: background 150ms linear
    transition: background 150ms linear
    &.open
      background: rgba(0, 0, 0, 0.97)
      .large-image__wrapper
        background-color: transparent
      header, nav, .large-image__counter, .large-image__thumbs
        opacity: 1

    header
      position: absolute
      top: 0
      left: 0
      width: 100%
      padding: 2.5%
      text-align: right
      z-index: 9999
      opacity: 0
      button
        display: inline-block
        padding: 15px
        width: 50px
        height: 50px
        text-align: center
        opacity: 0.8
        background: url(../scripts/vendor/fluid-image/images/close.png) center center no-repeat
        -webkit-transition: transform 100ms linear
        -moz-transition: transform 100ms linear
        transition: transform 100ms linear
        &:hover
          opacity: 1
          -webkit-transform: rotate(90deg)
          -moz-transform: rotate(90deg)
          transform: rotate(90deg)
        img
          display: block
          width: 100%

    nav
      display: none
      position: absolute
      top: 0
      bottom: 0
      margin: auto
      width: 100%
      height: 0px
      z-index: 999
      opacity: 0
      button
        position: absolute
        width: 60px
        height: 60px
        border: 1px #fff solid
        border-radius: 50%
        opacity: 0.8
        background: rgba(0, 0, 0, 0.5) 50% 50% no-repeat
        background-size: 20px 28px
        -webkit-transition: all 100ms linear
        -moz-transition: all 100ms linear
        transition: all 100ms linear
        &.disabled
          opacity: 0.15 !important
        &:hover
          opacity: 1
        &:first-child
          left: 2.5%
          background-image: url(../scripts/vendor/fluid-image/images/chevron-left.png)
          &:hover
            background-position: 42% 50%
        &:last-child
          right: 2.5%
          background-image: url(../scripts/vendor/fluid-image/images/chevron-right.png)
          &:hover
            background-position: 58% 50%
      
        img
          display: inline-block
          height: 100%

  &__content
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 99
  
  &__caption
    position: absolute
    top: 2.5%
    left: 0
    right: 0
    margin: auto
    text-align: center
    opacity: 0
    z-index: 999
    -webkit-transition: opacity 100ms linear
    -moz-transition: opacity 100ms linear
    transition: opacity 100ms linear
    &.show
      opacity: 1
    p
      display: inline-block
      padding: 10px 30px
      font-size: 20px
      font-weight: 200
      letter-spacing: 1px
      background: rgba(0, 0, 0, 0.3)

  
  &__counter
    position: absolute
    display: inline-block
    padding: 2.5%
    z-index: 999
    opacity: 0
    p
      padding: 10px 0
      font-size: 36px
      font-weight: 100
      letter-spacing: 5px
      line-height: 1
  &__thumbs
    position: absolute
    bottom: 0
    left: 0
    right: 0
    margin: auto
    text-align: center
    background: rgba(0, 0, 0, 0.8)
    z-index: 999
    opacity: 0
    div
      position: relative
      display: inline-block
      vertical-align: middle
      width: 70px
      height: 70px
      margin: 1px
      background-size: cover
      background-position: center center
      background-repeat: no-repeat
      overflow: hidden
      cursor: pointer
      &:hover
        opacity: 0.7
      &.active
        box-shadow: inset 0px 4px 0px 0px #009040
  
  
body.fluid-image-mobile
  .large-image__overlay
    nav
      opacity: 0
      button
        width: 0
        height: 0
        padding: 0
        overflow: hidden
        
