.site-title
	display: block
	margin-bottom: 30px
	padding: 60px 0 30px 0
	text-align: center
	p
		font-size: 14px
		font-weight: 700
		text-transform: uppercase
		letter-spacing: 1px
		color: #000
		opacity: 0.2
	p + h1
		margin-top: 10px
	h1
		position: relative
		display: inline-block
		margin: 0 auto
		padding-bottom: 30px
		max-width: 800px
		&::after
			content: ""
			position: absolute
			bottom: 0
			left: 0
			right: 0
			margin: auto
			width: 120px
			height: 2px
			background: $secondaryColor