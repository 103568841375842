.photo
	display: block
	margin: 10px 20px 10px 20px
	padding-bottom: 0
	position: relative
	z-index: 99
	.layout-col--3 &, .layout-col--4 &
		margin: 0
	&--left
		float: left
		margin-left: 0px
	&--right
		float: right
		margin-right: 0px
	&--center
		margin: 10px 0
		text-align: center
		background: #eee
	&--wide
		margin: 10px 0
		figure
			width: 100%
			a
				width: 100%
			img
				width: 100%
		
	figure
		display: table
		width: 100%
		position: relative
		overflow: hidden
		margin: auto
		line-height: 1
		font-size: 0
		img
			max-width: 100%
			backface-visibility: hidden
      -webkit-backface-visibility: hidden
      +transform(translateZ(0))
		figcaption
			display: table-caption
			caption-side: bottom
			padding: 5px 0 5px 0
			left: 0
			right: 0
			bottom: 0
			background: rgba(255, 255, 255, 0.9)
			font-size: 10px
			text-align: right
			+transition(all 150ms linear)
			p, a, span
				font-size: 12px
				font-weight: 400
				color: #c0c0c0
			

		a
			display: inline-block
			text-align: center
			img
				+transition(opacity 150ms linear)

		&:hover
			a
				&::after
					content: "\f065"
					font-family: FontAwesome
					font-size: 26px
					position: absolute
					top: 5px
					right: 5px
					margin: auto
					padding: 12px
					width: 50px
					height: 50px
					color: $baseColor
					background: rgba(255,255,255, 0.8)
					box-shadow: 3px 3px 5px -3px #000
					z-index: 999
					line-height: 1
				+ figcaption
					top: 100%
				img
					opacity: 0.7
		