.gallery
	display: block
	position: relative
	&__images
		display: block
		margin: -1%
		+clearfix
		.item
			display: block
			width: 23%
			margin: 1%
			float: left
			a, img
				display: block
				width: 100%