.site-footer
  bottom: 0
  left: 0
  right: 0
  width: 100%
  margin: auto
  padding: 25px
  text-align: center
  background: #fff
  border-top: 1px #ddd solid
  +flex-grow(0)
  +order(333)
  a
    color: $fontSecondaryColor