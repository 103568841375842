.squares
	position: relative
	display: block
	width: 100%
	overflow: hidden
	&__content
		display: table
		margin: -20px
		text-align: left
		border-spacing: 20px
	.square
		display: table-cell
		vertical-align: top
		width: 25%
		color: $baseColor
		background: #fff
		border-radius: 5px
		+transition(all 200ms linear)
		&__content
			position: relative
			display: block
			padding: 20px 25px
			width: 100%
			height: 100%
			color: inherit
		h4
			position: relative
			margin-bottom: 10px
			line-height: 1
			color: inherit
			&::before
				content: ""
				position: absolute
				top: 0.5em
				left: -25px
				width: 15px
				height: 2px
				background: $secondaryColor
		p, a, ul, ol
			line-height: 1.4
		a
			text-decoration: none

	.square:hover
		color: #fff
		background: $baseColor



+media-query-max($netbook)
	.squares
		&__content
			display: block
			padding-top: 30px
			text-align: center
		.square
			display: inline-block
			width: 100%
			max-width: 300px
			margin: 20px
			color: #fff
			color: rgba(255, 255, 255, 0.7)
			background: transparent
			border-top: 5px #fff solid
			border-radius: 0px
			&:hover
				background: transparent
			h1
				line-height: 1
			h4
				&::before
					display: none
