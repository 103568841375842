.post
	margin-bottom: 30px
	&-date
		display: block
		margin-bottom: 10px
		p
			font-size: 13px
			font-weight: 300
			text-transform: uppercase
			letter-spacing: 2px
	&-title
		display: block
		margin-bottom: 15px
		h5
			font-family: $baseFont
			font-weight: 700
			line-height: 1.2
			a
				text-decoration: none
	&-text
		display: block
		margin-bottom: 15px
		p
			line-height: 2em