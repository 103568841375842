.offers
  position: relative
  display: block
  &__list
    display: block
    font-size: 0
    text-align: center
  &__offer
    display: inline-block
    vertical-align: top
    width: 25%
    min-width: 200px
    padding: 20px
    text-align: center
    a
      display: inline-block
      color: $fontBaseColor
      &:hover
        .offer__ico
          background: darken($baseColor, 10%)
        .flip .card
          -webkit-transform: rotateY(-180deg)
      span
        display: block
        font-size: 14px
        font-weight: 600

.offer
  &__ico
    display: inline-block
    margin-bottom: 20px
    padding: 20px
    width: 100px
    height: 100px
    line-height: 60px
    background: $baseColor
    border-radius: 50%
    +transition(background 200ms linear)
    img, svg
      display: inline-block
      vertical-align: middle
      line-height: 1








.flip 
  width: 100px
  height: 100px
  margin: auto
  margin-bottom: 20px
  position: relative
  -webkit-perspective: 200

.flip .card 
  width: 100%
  height: 100%
  -webkit-transform-style: preserve-3d
  -webkit-transition: 0.5s

.flip .card .face 
  width: 100%
  height: 100%
  position: absolute
  z-index: 2
